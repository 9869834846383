import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  IProduct,
  IProductBasicInformation,
  IProductCreateDto,
  IProductUpdateDto,
  IProductWithRelations,
} from '../interfaces';
import {BaseService} from '@shared/services';
import {Injectable} from '@angular/core';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {environment} from '@env/environment';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {UtilityClass} from '@shared/utils/utility.class';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {omit as _omit} from 'lodash';

export interface IProductState {
  products: IProductWithRelations[];
  selectedId: string | null;
  count: number;

  productsBasicInformation: IProductBasicInformation[];
}

const _DEFAULT: IProductState = {
  products: [],
  selectedId: null,
  count: 0,
  productsBasicInformation: []
}

export namespace ProductActions {
  export class Find {
    static readonly type: string = '[Product] Find';
    constructor(public filters: PrismaFilter<IProductWithRelations>) {}
  }

  export class FindBasicInformation {
    static readonly type: string = '[Product] Find Basic Information';
    constructor(public filters: PrismaFilter<IProductWithRelations>) {}
  }

  export class Count {
    static readonly type: string = '[Product] Count';
    constructor(public filters: PrismaFilter<IProductWithRelations>) {}
  }

  export class FindById {
    static readonly type: string = '[Product] FindById';
    constructor(public id: string, public filters?: PrismaFilter<IProductWithRelations>) {}
  }

  export class Post {
    static readonly type: string = '[Product] Post';
    constructor(public product: IProductCreateDto) {}
  }

  export class CreateAsDraft {
    static readonly type: string = '[Product] Create As Draft';
    constructor(public product: IProductCreateDto) {}
  }

  export class DeleteById {
    static readonly type: string = '[Product] DeleteById';
    constructor(public id: string) {}
  }

  export class Publish {
    static readonly type: string = '[Product] Publish';
    constructor(public id: string) {}
  }

  export class Draft {
    static readonly type: string = '[Product] Draft';
    constructor(public id: string) {}
  }


  export class Reject {
    static readonly type: string = '[Product] Reject';
    constructor(public id: string) {}
  }

  export class Patch {
    static readonly type: string = '[Product] Patch';
    constructor(public id: string, public product: IProductUpdateDto) {}
  }

  export class Reset {
    static readonly type: string = '[Product] Reset';
    constructor() {}
  }
}

@State<IProductState>({
  name: 'ProductState',
  defaults: _DEFAULT
})
@Injectable()
export class ProductState {
  private readonly SERVER: string = environment.SERVER;
  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getProducts({products}: IProductState): IProductWithRelations[] {
    return products;
  }

  @Selector()
  static getProductsBasicInformation({productsBasicInformation}: IProductState): IProductBasicInformation[] {
    return productsBasicInformation;
  }

  @Selector()
  static getProductSelected({products, selectedId}: IProductState): IProductWithRelations | null {
    return products.find(({id}): boolean => id === selectedId) ?? null;
  }

  @Selector()
  static getCount({count}: IProductState): number {
    return count;
  }


  @Action(ProductActions.Find)
  async find({patchState}: StateContext<IProductState>, {filters}: ProductActions.Find): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations[]> = await firstValueFrom(this.baseService.get<IProductWithRelations[]>(`${this.SERVER}/products`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: response.entity!,
      selectedId: null,
    });
  }

  @Action(ProductActions.FindBasicInformation)
  async findBasicInformation({patchState}: StateContext<IProductState>, {filters}: ProductActions.FindBasicInformation): Promise<void> {
    const response: DataBaseServiceResponse<IProductBasicInformation[]> = await firstValueFrom(this.baseService.get<any>(`${this.SERVER}/products/basic-information`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      productsBasicInformation: response.entity ?? [],
    });
  }

  @Action(ProductActions.FindById)
  async findById({patchState, getState}: StateContext<IProductState>, {id, filters}: ProductActions.FindById): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.get<IProductWithRelations>(`${this.SERVER}/products/${id}`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ProductActions.Count)
  async count({patchState}: StateContext<IProductState>, {filters}: ProductActions.Count): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IProductWithRelations>(`${this.SERVER}/products/count`, filters));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }


  @Action(ProductActions.Post)
  async post({patchState, getState}: StateContext<IProductState>, {product}: ProductActions.Post): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.post<IProductWithRelations>(`${this.SERVER}/products`, product));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ProductActions.CreateAsDraft)
  async createAsDraft({patchState, getState}: StateContext<IProductState>, {product}: ProductActions.CreateAsDraft): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.post<IProductWithRelations>(`${this.SERVER}/products/draft`, product));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ProductActions.DeleteById)
  async deleteById({patchState, getState}: StateContext<IProductState>, {id}: ProductActions.DeleteById): Promise<void> {
    const response: DataBaseServiceResponse<IProduct> = await firstValueFrom(this.baseService.delete<IProduct>(`${this.SERVER}/products/${id}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    // patchState({
    //   products: UtilityClass.deleteItemByProp<IProductWithRelations>(getState().products, 'id', id),
    //   selectedId: null,
    // });
  }

  @Action(ProductActions.Publish)
  async publish({patchState, getState}: StateContext<IProductState>, {id}: ProductActions.Publish): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.post<IProductWithRelations>(`${this.SERVER}/products/${id}/publish`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    })
  }

  @Action(ProductActions.Reject)
  async reject({patchState, getState}: StateContext<IProductState>, {id}: ProductActions.Reject): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.post<IProductWithRelations>(`${this.SERVER}/products/${id}/reject`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    })
  }

  @Action(ProductActions.Draft)
  async draft({patchState, getState}: StateContext<IProductState>, {id}: ProductActions.Draft): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.post<IProductWithRelations>(`${this.SERVER}/products/${id}/draft`, {}));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    })
  }

  @Action(ProductActions.Patch)
  async patch({patchState, getState}: StateContext<IProductState>, {product, id}: ProductActions.Patch): Promise<void> {
    const response: DataBaseServiceResponse<IProductWithRelations> = await firstValueFrom(this.baseService.patch<IProductWithRelations>(`${this.SERVER}/products/${id}`, _omit(product, ['organizationId'])));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      products: UtilityClass.updateOrPushItems<IProductWithRelations>(getState().products, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(ProductActions.Reset)
  async reset({setState}: StateContext<IProductState>, _: ProductActions.Reset): Promise<void> {
    setState(_DEFAULT);
  }
}
